.logout {
  /* display: block!important; */
}
.createMeetWrapper {
  padding: 20px;
  border: 1px solid #f4f4f4;
  margin: 20px;
  justify-content: center;
  height: 160px;
}
.createMeet {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.createMeet button {
  max-width: 250px;
  width: 100%;
}

/* staper screen css start */
.Zilla-Slab-font {
  font-family: 'Zilla Slab', sans-serif !important;
}
.DM-Sans-font {
  font-family: 'DM Sans', sans-serif !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.stapper-main-div {
  margin-bottom: 47px;
}
.stapper-circle-div {
  display: flex;
  column-gap: 18px;
  justify-content: center;
  min-height: 32px;
  align-items: center;
}
.back-arrow-img {
  cursor: pointer;
  float: left;
}
.stapper-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #d9d9d9;
  box-shadow: 1px 0px 4px 0px #0000001a inset;
}
.stapper-active {
  background: #4a4a4a !important;
  box-shadow: 1px 4px 4px 0px #0000001a inset !important;
  width: 24px;
  border-radius: 8px;
  /* transition: background-color 0.3s, width 0.3s !important; */
  /* transition-delay: 0.1s, 0.1s, 0s !important; */
}
.common-padding-div {
  padding-top: 88px;
  padding-bottom: 88px;
}
.common-stepper-padding {
  padding-top: 35px;
  padding-bottom: 42px;
}
.common-title-sec {
  margin-bottom: 56px;
}
.screen-common-title {
  color: #222525;
  font-size: 40px;
  font-weight: 600;
  font-family: Zilla Slab;
  text-align: center;
  margin-top: 0px;
}
.screen-sub-title {
  color: #222525;
  font-size: 32px;
  font-weight: 600;
  font-family: Zilla Slab;
  text-align: center;
  margin-top: 0px;
}
.screen-small-title {
  color: #222525;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 8px;
}
.screen-para-text {
  color: #4e5151;
  font-size: 15px;
  font-weight: 400;
  margin-top: 20px;
  text-align: center;
  font-family: 'DM Sans', sans-serif;
}
/* .choose-lunguage-main-div {
  max-width: 625px;
  margin: 0 auto;
} */
.lunguage-btn-main-div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
}

.survey-btn-main-div {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  grid-gap: 24px;
  gap: 24px;
  max-width: 625px;
  margin: 0 auto;
}

.how_learned_btn {
  border: 1px solid #ededed;
  box-shadow: 0px 4px 6px 2px #2225250f;
  background: #ffffff;
  border-radius: 8px;
  height: 60px;
  padding: 16px 24px;
  width: 100%;
  max-width: 300px;
  text-align: left;
  color: #222525;
  font-size: 15px;
  font-weight: 700;
  font-family: 'DM Sans', sans-serif;
}

.lunguage-btn-cls {
  border: 1px solid #ededed;
  box-shadow: 0px 4px 6px 2px #2225250f;
  background: #ffffff;
  border-radius: 8px;
  height: 60px;
  padding: 16px 24px;
  width: 100%;
  max-width: 300px;
  text-align: left;
  color: #222525;
  font-size: 15px;
  font-weight: 700;
  font-family: 'DM Sans', sans-serif;
}
.lunguage-btn-cls:hover {
  background: #daede8;
  border: 1px solid #0684661a;
}
.lunguage-btn-cls img {
  margin-right: 16px;
}
.lunguage-btn-cls-active img {
  margin-right: 16px;
}
.common-btn-cls {
  min-width: 280px;
  height: 48px;
  padding: 12px 31px 12px 31px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
  margin-top: 40px;
  font-family: 'DM Sans', sans-serif;
}
.outline-btn-class {
  background: #ffffff;
  color: #222525;
  border: 1px solid #2225254d;
}
.outline-btn-class:hover {
  background: #068466;
  color: #ffffff;
  border: 1px solid #068466;
}

.outline-btn-class:disabled {
  background: #ecebea;
  color: #8e8f94;
  border: 1px solid #daede8;
}

.green-btn-class {
  background: #068466;
  color: #ffffff;
  border: 1px solid #068466;
}
.green-btn-class:hover {
  background: #056a52;
  border: 1px solid #056a52;
}
.green-btn-class:disabled {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
}
.green-color {
  color: #068466;
}
.contains-div h5 {
  font-size: 18px;
  font-weight: 700;
  color: #222525;
  margin-bottom: 18px;
  font-family: 'DM Sans', sans-serif;
}
.contains-div p {
  color: #4e5151;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0px;
  line-height: 24px;
  font-family: 'DM Sans', sans-serif;
}
.contains-div p b {
  font-weight: 700;
}
.why-learn-card-div {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  border: 1px solid #e9e9e9;
  border-radius: 20px;
  padding: 28px 28px;
  box-shadow: 3px 3px 6px 0px #0000000d;
}
.left-div-cls {
  min-width: 230px;
}
.method-btn {
  min-height: 169px;
  min-width: 192px;
  padding: 12px 31px 12px 31px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  border: 1px solid #e9e9e9;
  font-family: 'DM Sans', sans-serif;
}
.hover-hide:hover {
  background: #ffffff !important;
  color: #222525 !important;
  border: 1px solid #e9e9e9 !important;
}
.method-btn:hover {
  background: #daede8;
  color: #222525;
  border: 1px solid #daede8;
}
.wrong:hover {
  background: #ed6a5e1a;
}
.hover-hide:active {
  background: #daede8 !important;
  color: #222525 !important;
  border: 1px solid #daede8 !important;
}
.outline-btn-active {
  background: #daede8;
  color: #222525;
  border: 1px solid #daede8;
}

.hover-hide:disabled {
  background: #ecebea !important;
  color: #8e8f94 !important;
  border: 1px solid #daede8 !important;
}
.method-main-div {
  max-width: 500px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  margin: 0 auto;
}
.lung-text {
  color: #068466;
  font-size: 40px;
  display: block;
  margin-bottom: 30px;
  font-weight: 400;
  font-family: 'Noto Sans SC', sans-serif;
  text-shadow: 2px 2px rgb(6 132 102 / 10%);
  cursor: pointer;
}
.tabs-word-btn {
  min-width: 136px;
  min-height: 72px;
  font-weight: 500;
  box-shadow: 0px 4px 6px 0px #2225250f;
  font-family: 'Noto Sans SC', sans-serif;
}
.tabs-word-main-div {
  max-width: 420px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  margin: 0 auto;
}
.links-text {
  color: #222525;
  font-size: 14px;
  margin-top: 28px;
  cursor: pointer;
  font-family: 'DM Sans', sans-serif;
}
.mb-15 {
  margin-bottom: 15px;
}
.common-points-list {
  margin: 0px;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}
.list-title {
  font-size: 15px;
  color: #4e5151;
  font-weight: 400;
  margin-bottom: 7px;
}
.common-points-list li {
  font-size: 15px;
  color: #4e5151;
  font-weight: 400;
  line-height: 26px;
  display: flex;
  align-items: center;
}
.common-points-list li svg {
  color: #068466;
  font-size: 20px;
  margin-right: 7px;
}
.points-div {
  margin: 15px auto 0 auto;
  width: 100%;
  max-width: fit-content;
}
.result-img-div {
  text-align: center;
  margin-bottom: 45px;
}
.login-field-main-div {
  max-width: 416px;
  margin: 66px auto 0 auto;
}
.login-field-main-div form .form-group {
  margin-bottom: 32px;
}
.input-btn {
  width: fit-content;
  margin: 40px auto 0 auto;
  cursor: pointer;
}
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-item {
  position: relative;
  display: inline-block;
}

.tooltip-item .tooltip-text {
  visibility: hidden;
  width: 100%;
  background-color: white;
  color: #222525b2;
  text-align: left;
  border-radius: 8px;
  padding: 16px;
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, 63px);
  border: 1px solid #ededed;
  /* top: 19px; */
  line-height: 19.53px;
  opacity: 0;
  transition: opacity 0.3s;
  font-family: DM Sans;
  font-size: 15px;
  font-weight: 400;
  box-shadow: 0px 4px 6px 0px #2225250f;
  width: fit-content;
  max-width: 250px;
  white-space: nowrap;
}

.tooltip-item:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.tooltip-item.disabled .tooltip-text {
  visibility: hidden;
}
.tooltip-word-container .tooltip-text {
  min-width: 363px;
  white-space: unset;
  left: -20px !important;
  top: 0px !important;
  transform: translate(0%, 70px) !important;
  display: inline-table;
}
.success-screen-div {
  /* background-color: #e3e3e3; */
  height: calc(100vh - 130px);
  padding-top: 15px;
  padding-bottom: 15px;
  overflow-x: auto;
}
.result-ray-img {
  max-width: 650px;
  margin: 0 auto;
}
.result-avtar-img {
  position: relative;
  top: -90px;
}
.result-bottom-contains-div {
  margin-top: -90px;
}
.counter-flex-div {
  display: flex;
  column-gap: 5px;
  justify-content: center;
}
.No-worries-img-div {
  position: relative;
}
.No-worries-img1 {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
}
.No-worries-img2 {
  position: relative;
  z-index: 1;
  top: -15px;
}
.text-logo {
  margin-left: 6px;
  position: relative;
  top: 0px;
  width: 110px;
}
.box-shadow-cls {
  box-shadow: 3px 3px 6px 0px #0000000d;
  border: 1px solid #ecebea !important;
}

.error-msg {
  margin-top: 7px;
  color: red;
  font-size: 15px;
  margin-bottom: 0px;
}

/* .error {
  border-color: red;
} */

/* .marquee-container {
  position: relative;
   height: 100px;
   overflow: hidden;
   line-height: 30px;
 
   .marquee {
     top: 0;
     left: 100%;
     width: 100%;
     overflow: hidden;
     position: absolute;
     white-space: nowrap;
     animation: marquee 30s linear infinite;
     display: flex;
     justify-content: space-around;
   }
 
   .marquee2 {
     animation-delay: 15s;
   }
     p {
     color: #d3d3d3;
     font-family: 'DM Sans', sans-serif;
     font-size: 90px;
     font-weight: 700;
     line-height: 117px;
     position: relative;
     margin-bottom: 0px;
     padding: 0 32px;
   }
   
    p:before {
     content: '';
     display: inline-block;
     width: 20px;
     height: 20px;
     border-radius: 50px;
     background-color: #d3d3d3;
     position: absolute;
     top: 50%;
     left: 0%;
     transform: translate(-50%, -50%);
   }
  
 }
@keyframes marquee {
  0% {
    right: 100%;
  }
  100% {
    right: -100%
  }
} */

.we-estimate-div {
  padding-top: 50px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text {
  color: #d3d3d3;
  font-family: 'DM Sans', sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 65px;
  position: relative;
  margin-bottom: 0px;
  padding: 0 32px;
}

.text:before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  background-color: #d3d3d3;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-50%, -50%);
}

/* 
@keyframes marquee-right {
  0% {
    right: 100%;
  }
  100% {
    right: -100%
  }
} */
/* .scroll-word-div {
  overflow: hidden;
}
.scroll-word-flex {
  display: flex;
  column-gap: 35px;
}
.left-scroll-animation {
  animation: marquee-left 10s linear infinite running;
}
.right-scroll-animation {
  animation: marquee-right 10s linear infinite running;
}
.scroll-word-flex p {
  color: #d3d3d3;
  font-family: 'DM Sans', sans-serif;
  font-size: 90px;
  font-weight: 700;
  line-height: 117px;
  position: relative;
  margin-bottom: 0px;
  padding-left: 20px;
}
.scroll-word-flex p:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: #d3d3d3;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-50%, -50%);
} */

/* @keyframes marquee-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(-100%);
  }
}
@keyframes marquee-right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translate(0);
  }
}
 */

.step-indicator {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-indicator-item {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #d9d9d9;
  box-shadow: 1px 0px 4px 0px #0000001a inset;
  transition: background-color 0.5s ease;
  position: relative;
}

.step-indicator-item.active {
  background: #4a4a4a;
  box-shadow: 1px 4px 4px 0px #0000001a inset;
  width: 24px;
  border-radius: 8px;
}

/* Animation for active step indicator */
.step-indicator-item.active::before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #d9d9d9;
  box-shadow: 1px 0px 4px 0px #0000001a inset;

  z-index: -1;
  animation: slide 1s ease forwards;
}
.bottom-section {
  text-align: center;
}
.bottom-section .second {
  margin-top: 52px;
}

@keyframes slide {
  0% {
    width: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  100% {
    width: 20px;
    left: 0;
    transform: translateX(0);
  }
}

.hight_light_text {
  font-family: 'DM Sans';
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #4e5151;
  margin-bottom: 0px;
}
.hight_light_text_main_div {
  display: flex;
  gap: 8px;
  margin-top: 30px;
}
.hight_light_text_main_div svg {
  min-width: 16px;
}
.title_text_small_box {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  font-family: 'DM Sans';
  margin-bottom: 0px;
}
.link_text {
  color: #222525;
  text-decoration: underline;
  cursor: pointer;
}
.small_card_div {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-right: 60px !important;
}
.skeleton_loader_small_div {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  min-height: auto !important;
}
.small_card_div .check_icon_div {
  top: 16px !important;
}
.loader_main_div {
  text-align: center;
  margin-top: 50px;
}
.loader_main_div .spinner-border {
  color: #068466;
  --bs-spinner-width: 63px;
  --bs-spinner-height: 63px;
  --bs-spinner-border-width: 8px;
}
.loader_div .m-center.m-loading {
  padding: 0px;
}
.loader_div .m-center.m-loading .m-loading-ellipsis {
  height: 60px;
}
.loader_with_text_div {
  margin: 0 auto 0 auto;
  max-width: 760px;
  padding-left: 24px;
  padding-right: 24px;
  text-align: center;
  padding-top: 150px;
}
.loader_text {
  font-family: 'Zilla Slab', sans-serif;
  font-size: 32px;
  line-height: 39px;
  font-weight: 600;
  text-align: center;
  color: #222525;
  margin-bottom: 0px;
}
.loader_text_2 {
  font-family: 'Zilla Slab', sans-serif;
  font-size: 18px;
  line-height: 39px;
  font-weight: 600;
  text-align: center;
  color: #222525;
  margin-top: 15px;
  margin-bottom: 0px;
}

/* Modal css start */

.action_btn_text {
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  color: #373737;
  font-family: 'DM Sans', sans-serif;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 18px;
  padding-right: 10px;
  width: fit-content;
  margin-left: auto;
}
.common_modal_main_div .modal-body {
  padding-top: 60px;
  padding-bottom: 57px;
  padding-left: 54px;
  padding-right: 54px;
  background: linear-gradient(180deg, #fefefe 0%, #f7f7f7 100%);
}
.common_modal_main_div .modal-content {
  border-radius: 20px;
  overflow: hidden;
}
.modal_title_div {
  margin-bottom: 42px;
}
.modal_heading {
  font-size: 32px;
  color: #373737;
  line-height: 39px;
  font-weight: 600;
  margin-bottom: 0px;
  font-family: 'Zilla Slab', sans-serif;
  text-align: center;
}
.modal_close_icon_div {
  position: absolute;
  right: 26px;
  top: 26px;
  cursor: pointer;
}
.Pinyin_action_box {
  display: grid;
  grid-template-columns: 160px auto;
  column-gap: 50px;
  align-items: center;
  margin-bottom: 12px;
}
.Pinyin_btn_group {
  border: 1px solid #dbdbdb;
  border-radius: 6px;
}
.Pinyin_action_main_div {
  max-width: 550px;
  margin: 0 auto;
}
.Pinyin_btn_group .btn_cls {
  font-size: 15px;
  line-height: 20px;
  color: #595959;
  font-weight: 700;
  font-family: 'DM Sans', sans-serif;
  text-align: center;
  padding: 14px 22px;
}
.pinyin_btn_active {
  background-color: #068466 !important;
  box-shadow: 0px 8px 24px 0px #4545501a;
  color: #ffffff !important;
  border-radius: 6px !important;
}
.server_error_msg {
  color: #222525cc;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
.event_title_text {
  color: #222525;
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  font-family: 'DM Sans', sans-serif;
  margin-bottom: 0px;
}
.modal_btn_width {
  min-width: 203px;
}
.modal_btn_div {
  margin-top: 80px;
}
.sentence_list_main_div {
  display: flex;
  flex-direction: column;
  row-gap: 42px;
}
.sentence_english {
  color: #222525;
  font-size: 15px;
  font-weight: 600;
  font-family: 'DM Sans', sans-serif;
  line-height: 20px;
  margin-bottom: 8px;
}
.view_sentence_word_div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 6px;
}
.sentence_pinyin {
  color: #222525cc;
  font-size: 12px;
  font-weight: 400;
  font-family: 'DM Sans', sans-serif;
  line-height: 15px;
  margin-bottom: 3px;
  text-align: center;
}
.sentence_sentence_text {
  color: #373737;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 0px;
  letter-spacing: 0.26em;
  font-family: Noto Sans SC;
}
.word_detail_modal_main_div {
  max-width: 390px;
  min-width: 390px;
  box-shadow: -4px 4px 4px 0px #00000014;
  background: linear-gradient(136.18deg, #ecebea 21.54%, #f7f7f7 50.39%, #ecebea 87.1%);
  border: 1px solid #e3e2e1;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 27px;
  position: fixed;
  right: 0px;
  top: 247px;
  z-index: 999;
  /* transform: translate3d(0%, 0, 0); */
  right: -390px;
  transition:
    opacity 0.5s linear,
    right 0.5s ease-out;
}
.word_detail_modal_main_div.bounce-in-right {
  right: 0px !important;
}

.bounce-in-right {
  animation: bounce-in-right 0.5s ease 1;
}
@keyframes bounce-in-right {
  0% {
    opacity: 0;
    transform: translateX(390px);
  }
  60% {
    opacity: 1;
    transform: translateX(0px);
  }
  80% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(0);
  }
}

.word_details_head_div {
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  margin-bottom: 9px;
}
.big_word {
  font-family: 'Noto Sans SC', sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 57.92px;
  letter-spacing: 0.06em;
  color: #222525;
  margin-bottom: 0px;
  min-width: 120px;
}
.small_word {
  font-family: 'DM Sans', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  color: #4e5151;
  margin-bottom: 0px;
  text-align: right;
}
.speck_text {
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #222525;
  margin-bottom: 7px;
}
.tooltip_text_main_div {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}
.modal_tooltip_text {
  font-family: 'DM Sans', sans-serif;
  color: #222525;
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 0px;
  cursor: pointer;
}
.para_show_text {
  font-family: 'DM Sans', sans-serif;
  color: #4e5151;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 5px;
  margin-top: 8px;
}
.fadeincls {
  animation: fadeInEffect 0.6s linear;
  /* transition: transform ease-out 0.3s; */
}
@keyframes fadeInEffect {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOutEffect {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.modal_tooltip_text i {
  font-size: 18px;
  margin-left: 5px;
  color: #373737;
}
.divider_line {
  width: 100%;
  height: 1px;
  background-color: #cbcbcb;
  margin-top: 19px;
  margin-bottom: 19px;
}
.word_detail_small_text {
  color: #222525cc;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  font-family: 'DM Sans', sans-serif;
  margin-bottom: 16px;
}
.word_detail_small_text .bold_text {
  color: #222525;
  font-weight: 700;
  font-size: 15px;
}
.process_box_main_div {
  display: flex;
  align-items: center;
  gap: 4px;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  overflow: hidden;
  width: fit-content;
  margin-bottom: 17px;
}
.process_box {
  height: 15px;
  width: 24px;
  min-width: 24px;
  box-shadow: 0px 4px 4px 0px #0000001a inset;
  background: #d9d9d9;
}
.new_bg_color {
  background-color: #068466 !important;
  box-shadow: unset !important;
}
.learning_bg_color {
  background-color: #fc9f5b !important;
  box-shadow: unset !important;
}
.growing_bg_color {
  background-color: #7c8b9c !important;
  box-shadow: unset !important;
}
.developing_bg_color {
  background-color: #4a5f78 !important;
  box-shadow: unset !important;
}
.mastered_bg_color {
  background-color: #001e42 !important;
  box-shadow: unset !important;
}
.modal_tooltip_div {
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0px 4px 6px 0px #2225250f;
  padding-top: 18px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  border-radius: 8px;
  width: 276px;
  position: absolute;
  top: 25px;
  left: 8%;
  z-index: 9;
}
.tooltip_para_text {
  color: #222525b2;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  font-family: 'DM Sans', sans-serif;
  margin-bottom: 0px;
}
.curser_pointer {
  cursor: pointer;
}
.tooltip_btn {
  color: #222525 !important;
  padding: 8px 17px !important;
  background: #ffffff;
  border: 1px solid #2225254d !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  border-radius: 6px !important;
}
.mark_btn {
  background: #f2f1f1 !important;
  color: #62636b !important;
}
.tooltip_btn_div {
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-top: 12px;
  align-items: baseline;
}
/* Modal css end */

/* skeleton loader css start */
.skeleton_animation {
  display: block;
  box-shadow: 0px 0px 2px 0px rgba(255, 255, 255, 0.2392156863);
  border-radius: 6px;
  background: linear-gradient(to right, rgb(124 124 124 / 0%), rgb(88 88 88 / 20%) 50%, rgb(177 177 177 / 0%) 80%),
    #dbdbdb;
  background-repeat: repeat-y;
  background-size: 80px 500px;
  background-position: 0 0;
  animation: shine 1.5s infinite;
}
/* .word_detail_card_skeleton {
  max-width: 390px;
  min-width: 390px;
  box-shadow: -4px 4px 4px 0px #00000014;
  background: linear-gradient(136.18deg, #ecebea 21.54%, #f7f7f7 50.39%, #ecebea 87.1%);
  border: 1px solid #e3e2e1;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 27px;
  padding-bottom: 27px;
  position: fixed;
  right: 0px;
  top: 250px;
  z-index: 999;
} */
.word_details_head_div_skeleton {
  display: flex;
  grid-column-gap: 20px;
  column-gap: 20px;
  justify-content: space-between;
  margin-bottom: 10px;
}
.word_details_head_div_skeleton .left_skeleton {
  height: 58px;
  width: 100%;
}
.word_details_top_para {
  margin-bottom: 24px;
}
.word_details_top_para .para_skeleton {
  margin-bottom: 7px;
}
.para_skeleton {
  height: 23px;
  width: 100%;
  max-width: 200px;
}

@keyframes shine {
  to {
    background-position:
      100% 0,
      /* move highlight to right */ 0 0;
  }
}
/* skeleton loader css end */

/* slider css */
.word_detail_slider_div {
  display: none;
}
.word_detail_slider_div .carousel-control-prev,
.word_detail_slider_div .carousel-control-next {
  display: none;
}
.word_detail_slider_div .carousel-indicators {
  bottom: -32px;
  margin-bottom: 0px;
}
.word_detail_slider_div .carousel-indicators button {
  background-color: #d9d9d9 !important;
  height: 8px !important;
  width: 8px !important;
  border-radius: 100% !important;
  padding: 0px !important;
  margin: 0px 9px !important;
  border: 0px !important;
  box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.1) inset;
}
.word_detail_slider_div .carousel-indicators button.active {
  background-color: #4a4a4a !important;
  height: 8px !important;
  width: 24px !important;
  border-radius: 20px !important;
}
.section_story_container {
  max-width: 1010px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .word_detail_slider_div {
    display: block;
    margin-bottom: 32px;
  }
  .word_detail_slider_div .divider_line {
    display: none;
  }
  .word_detail_slider_div .carousel-item {
    transition: transform 0.3s ease-in-out !important;
  }
  .word_detail_slider_div .carousel-inner {
    overflow: visible;
  }
  .word_detail_slider_desktop_div {
    display: none;
  }
  .loader_text {
    font-size: 26px;
    line-height: 35px;
  }
  .word_detail_small_text {
    margin-bottom: 15px;
  }
  .first_slide_main_div {
    min-height: 140px;
  }
  .sec_slide_main_div {
    min-height: 140px;
  }
  .tooltip_text_main_div {
    row-gap: 13px;
  }
  .stapper-main-div {
    margin-bottom: 20px;
  }
  .back-arrow-img {
    width: 24px;
  }
  .overflow-hidden-mob {
    overflow: hidden;
  }
  .mob-lr-padding {
    padding-left: 24px;
    padding-right: 24px;
  }
  .common-padding-div {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  .common-stepper-padding {
    padding-top: 15px;
    padding-bottom: 24px;
  }
  .why-learn-main-div .common-btn-cls {
    /* opacity: 1 !important;
    transform: unset !important;
    transition-duration: unset !important;
    transition-timing-function: unset !important; */
    /* opacity: 1 !important;
      transform: translate(0) scale(1) !important; */
  }
  .survey-btn-main-div {
    justify-content: center;
  }
  .how_learned_btn {
    max-width: 625px;
  }
  .common-title-sec {
    margin-bottom: 20px;
  }
  .screen-common-title {
    font-size: 24px;
  }
  .screen-sub-title {
    font-size: 24px;
  }
  .screen-para-text {
    margin-top: 14px;
  }
  .common-btn-cls {
    width: 100%;
    margin-top: 30px;
  }
  .lunguage-btn-cls {
    max-width: 100%;
    font-size: 18px;
  }
  .why-learn-card-div {
    flex-wrap: wrap;
    padding: 15px 24px;
    box-shadow: 0px 4px 6px 2px #0000000f;
  }
  .left-div-cls {
    min-width: 100%;
    text-align: center;
  }
  .left-div-cls .img-div {
    margin-bottom: 24px;
  }
  .method-btn {
    box-shadow: 0px 4px 6px 2px #2225250f;
  }
  .screen-small-title {
    font-size: 18px;
  }
  .result-title {
    font-size: 24px;
  }
  .result-img-div {
    margin-top: 24px;
    margin-bottom: 30px;
  }
  .screen-sub-title br {
    display: none;
  }
  .result-bottom-contains-div {
    overflow: hidden;
    margin-top: -60px;
  }
  .result-bottom-contains-div .common-title-sec {
    margin-bottom: 24px;
  }
  .common-btn-cls {
    min-width: 250px;
    padding: 12px 10px 12px 10px;
  }
  .scroll-word-flex p {
    font-size: 26px;
    padding-left: 12px;
    line-height: 40px;
  }
  .scroll-word-flex p:before {
    width: 8px;
    height: 8px;
  }
  .scroll-word-flex {
    grid-column-gap: 20px;
    column-gap: 20px;
  }
  .tooltip-text {
    min-width: 210px;
  }
  .success-screen-div {
    height: auto;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .result-ray-img {
    max-width: 100%;
  }
  .result-avtar-img {
    position: relative;
    top: -50px;
    max-width: 147px;
  }
  .text {
    font-size: 30px;
    line-height: 45px;
    padding: 0 20px;
  }
  .text:before {
    width: 10px;
    height: 10px;
  }
  .we-estimate-div {
    padding-top: 15px;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .why-learn-card-div {
    border-radius: 6px;
  }
  .tooltip-word-container .tooltip-text {
    min-width: 100%;
    left: 0px !important;
    padding: 10px;
  }
  .text-logo {
    width: 76px;
  }
  .bottom-section {
    text-align: center;
    /* .second {
      margin-top: 40px;
    } */
  }
  .stapper-logo-main-div {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .small_card_div {
    padding-right: 40px !important;
  }
  .link_text {
    display: block;
  }
  .common_modal_main_div .modal-dialog {
    margin: 0px;
  }
  .common_modal_main_div .modal-content {
    border-radius: 0px;
  }
  .common_modal_main_div .modal-body {
    padding-top: 76px;
    padding-bottom: 26px;
    padding-left: 24px;
    padding-right: 24px;
    min-height: calc(100vh - 2px);
  }
  .mobile_align_div {
    display: flex;
    flex-direction: column;
  }
  .Pinyin_action_main_div {
    flex: 1 1;
    width: 100%;
  }
  .modal_close_icon_div {
    right: 20px;
    top: 20px;
  }
  .event_title_text {
    margin-bottom: 14px;
  }
  .Pinyin_action_box {
    grid-template-columns: 1fr;
    margin-bottom: 36px;
  }
  .Pinyin_action_box:last-child {
    margin-bottom: 0px;
  }
  .modal_heading {
    font-size: 24px;
    line-height: 28px;
  }
  .Pinyin_btn_group .btn_cls {
    padding: 14px 15px;
  }
  .sentence_english {
    margin-bottom: 16px;
  }
  .word_detail_modal_main_div {
    max-width: 100%;
    min-width: 100%;
    position: fixed;
    top: unset !important;
    right: 0px;
    bottom: -100%;
    z-index: 9999999;
    box-shadow: 0px -4px 12px 0px #00000014;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 0px;
    padding-bottom: 28px;
    transition:
      opacity 0.5s linear,
      bottom 0.5s ease-out;
  }
  .word_detail_modal_main_div.bounce-in-right {
    bottom: 0px;
  }

  .bounce-in-right {
    animation: bounce-in-right 0.5s ease 1;
  }
  @keyframes bounce-in-right {
    0% {
      opacity: 0;
      transform: translateY(390px);
    }
    60% {
      opacity: 1;
      transform: translateY(0px);
    }
    80% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .common_modal_main_div.modal.show {
    z-index: 9999999;
  }
  .modal_tooltip_div {
    bottom: 25px;
    left: 2%;
    top: unset;
  }
  /* .word_detail_card_skeleton {
    max-width: 100%;
    min-width: 100%;
    position: fixed;
    top: unset !important;
    right: 0px;
    bottom: 0px;
    z-index: 9999999;
    box-shadow: 0px -4px 12px 0px #00000014;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 0px;
    transition: opacity 0.5s linear, bottom 0.5s ease-out;
  } */
  .word_detail_card_skeleton .sk_mob_hide {
    display: none;
  }
  .word_detail_card_skeleton .para_skeleton {
    height: 24px !important;
  }
  .skeleton_mob_space {
    padding-bottom: 32px;
  }
  .skeleton_mob_space {
    display: flex;
    flex-direction: column;
    row-gap: 13px;
  }
  .skeleton_mob_space .para_skeleton {
    margin-bottom: 0px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .scroll-word-flex p {
    font-size: 35px;
    padding-left: 12px;
    line-height: 65px;
  }
  .scroll-word-flex p:before {
    width: 10px;
    height: 10px;
  }
  .scroll-word-flex {
    grid-column-gap: 20px;
    column-gap: 20px;
  }
  .result-ray-img {
    max-width: 395px;
  }
  img.result-avtar-img {
    max-width: 140px;
  }
  .result-img-div {
    margin-bottom: 20px;
  }
  .success-screen-div .screen-small-title {
    font-size: 20px;
  }
  .success-screen-div .screen-sub-title {
    font-size: 26px;
  }
  .success-screen-div .common-btn-cls {
    margin-top: 20px;
  }
  .text {
    font-size: 35px;
    line-height: 45px;
    padding: 0px 25px;
  }
  .success-screen-div {
    height: calc(100vh - 95px);
  }
  .text:before {
    width: 15px;
    height: 15px;
  }
  .common-stepper-padding {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .stapper-main-div {
    margin-bottom: 15px;
  }
  .common-title-sec {
    margin-bottom: 25px;
  }
  .screen-sub-title {
    font-size: 26px;
  }
  .text-logo {
    max-width: 83px;
  }
  .common-btn-cls {
    margin-top: 30px;
  }
  .screen-para-text {
    margin-top: 10px;
  }
  .tabs-word-main-div {
    grid-gap: 15px;
    gap: 15px;
  }
  .tabs-word-btn {
    min-height: 60px;
    padding: 10px 20px 10px 20px;
  }
  .links-text {
    font-size: 13px;
    margin-top: 12px;
  }
  .tooltip-word-container .tooltip-text {
    top: -15px !important;
  }
  .login-field-main-div {
    margin: 40px auto 0 auto;
  }
  .login-field-main-div form .form-group {
    margin-bottom: 25px;
  }
  .bottom-section {
    text-align: center;
    /* .second {
      margin-top: 40px;
    } */
  }
  .common_modal_main_div .modal-dialog {
    max-width: 650px;
  }
  /* .word_detail_modal_main_div {
    top: 170px;
  } */
  /* .word_detail_card_skeleton {
    top: 170px;
  } */
  .section_story_container {
    max-width: 300px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .scroll-word-flex p {
    font-size: 45px;
    padding-left: 15px;
    line-height: 75px;
  }
  .scroll-word-flex p:before {
    width: 10px;
    height: 10px;
  }
  .scroll-word-flex {
    grid-column-gap: 20px;
    column-gap: 20px;
  }
  .result-ray-img {
    max-width: 395px;
  }
  img.result-avtar-img {
    max-width: 140px;
  }
  .result-img-div {
    margin-bottom: 20px;
  }
  .success-screen-div .screen-small-title {
    font-size: 20px;
  }
  .success-screen-div .screen-sub-title {
    font-size: 26px;
  }
  .success-screen-div .common-btn-cls {
    margin-top: 20px;
  }
  .text {
    font-size: 35px;
    line-height: 45px;
    padding: 0px 25px;
  }
  .success-screen-div {
    height: calc(100vh - 95px);
  }
  .text:before {
    width: 15px;
    height: 15px;
  }
  .common-stepper-padding {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .stapper-main-div {
    margin-bottom: 15px;
  }
  .common-title-sec {
    margin-bottom: 25px;
  }
  .screen-sub-title {
    font-size: 26px;
  }
  .text-logo {
    max-width: 83px;
  }
  .common-btn-cls {
    margin-top: 30px;
  }
  .screen-para-text {
    margin-top: 10px;
  }
  .tabs-word-main-div {
    grid-gap: 15px;
    gap: 15px;
  }
  .tabs-word-btn {
    min-height: 60px;
    padding: 10px 20px 10px 20px;
  }
  .links-text {
    font-size: 13px;
    margin-top: 12px;
  }
  .tooltip-word-container .tooltip-text {
    top: -15px !important;
  }
  .login-field-main-div {
    margin: 40px auto 0 auto;
  }
  .login-field-main-div form .form-group {
    margin-bottom: 25px;
  }
  .bottom-section {
    text-align: center;
    /* .second {
      margin-top: 40px;
    } */
  }
  /* .word_detail_modal_main_div {
    top: 190px;
  } */
  /* .word_detail_card_skeleton {
    top: 190px;
  } */
  .section_story_container {
    max-width: 490px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .scroll-word-flex p {
    font-size: 60px;
    line-height: 85px;
  }
  .scroll-word-flex p:before {
    width: 15px;
    height: 15px;
  }
  .scroll-word-flex {
    grid-column-gap: 30px;
    column-gap: 30px;
  }
  .result-ray-img {
    max-width: 395px;
  }
  img.result-avtar-img {
    max-width: 140px;
  }
  .result-img-div {
    margin-bottom: 10px;
  }
  .success-screen-div .screen-small-title {
    font-size: 18px;
    margin-bottom: 5px;
  }
  .success-screen-div .common-title-sec {
    margin-bottom: 8px !important;
  }
  .success-screen-div .points-div {
    margin: 8px auto 0 auto;
  }
  .success-screen-div .screen-sub-title {
    font-size: 26px;
  }
  .success-screen-div .common-btn-cls {
    margin-top: 20px;
  }
  .text {
    font-size: 35px;
    line-height: 45px;
    padding: 0px 25px;
  }
  .success-screen-div {
    height: calc(100vh - 95px);
  }
  .text:before {
    width: 15px;
    height: 15px;
  }
  .common-stepper-padding {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .stapper-main-div {
    margin-bottom: 15px;
  }
  .common-title-sec {
    margin-bottom: 25px;
  }
  .screen-sub-title {
    font-size: 26px;
  }
  .text-logo {
    max-width: 83px;
  }
  .common-btn-cls {
    margin-top: 30px;
  }
  .screen-para-text {
    margin-top: 10px;
  }
  .tabs-word-main-div {
    grid-gap: 15px;
    gap: 15px;
  }
  .tabs-word-btn {
    min-height: 60px;
    padding: 10px 20px 10px 20px;
  }
  .links-text {
    font-size: 13px;
    margin-top: 12px;
  }
  .tooltip-word-container .tooltip-text {
    top: -15px !important;
  }
  .login-field-main-div {
    margin: 40px auto 0 auto;
  }
  .login-field-main-div form .form-group {
    margin-bottom: 25px;
  }
  .bottom-section {
    text-align: center;
    /* .second {
      margin-top: 40px;
    } */
  }
  /* .word_detail_modal_main_div {
    top: 140px;
  } */
  /* .word_detail_card_skeleton {
    top: 140px;
  } */
}

@media only screen and (min-width: 1441px) and (max-width: 1660px) {
  .result-ray-img {
    max-width: 480px;
  }
  img.result-avtar-img {
    max-width: 185px;
  }
  .result-img-div {
    margin-bottom: 20px;
  }
  .success-screen-div .screen-small-title {
    font-size: 20px;
  }
  .success-screen-div .screen-sub-title {
    font-size: 26px;
  }
  .success-screen-div .common-btn-cls {
    margin-top: 20px;
  }
  .text {
    font-size: 35px;
    line-height: 45px;
    padding: 0px 25px;
  }
  .success-screen-div {
    height: calc(100vh - 95px);
  }
  .text:before {
    width: 15px;
    height: 15px;
  }
  .common-stepper-padding {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .stapper-main-div {
    margin-bottom: 20px;
  }
  .common-title-sec {
    margin-bottom: 30px;
  }
  .common-btn-cls {
    margin-top: 30px;
  }
  .screen-para-text {
    margin-top: 10px;
  }
  .tabs-word-main-div {
    grid-gap: 15px;
    gap: 15px;
  }
  .tabs-word-btn {
    min-height: 60px;
    padding: 10px 20px 10px 20px;
  }
  .links-text {
    margin-top: 15px;
  }
  .tooltip-word-container .tooltip-text {
    top: -15px !important;
  }
  .login-field-main-div {
    margin: 40px auto 0 auto;
  }
  .login-field-main-div form .form-group {
    margin-bottom: 25px;
  }
  .bottom-section {
    text-align: center;
    /* .second {
      margin-top: 40px;
    } */
  }
  /* .word_detail_modal_main_div {
    top: 140px;
  } */
  /* .word_detail_card_skeleton {
    top: 140px;
  } */
}

/* staper screen css end */

@media only screen and (min-width: 1200px) and (max-width: 1379px) {
  .section_story_container {
    max-width: 700px;
  }
}
@media only screen and (min-width: 1380px) and (max-width: 1440px) {
  .section_story_container {
    max-width: 780px;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1550px) {
  .section_story_container {
    max-width: 860px;
  }
}

@media only screen and (min-width: 1551px) and (max-width: 1680px) {
  .section_story_container {
    max-width: 920px;
  }
}

/* New Component Global Css Start */
:root {
  --primary-color: #068466;
  --light-orange: #f7bf6b;
  --light-green: #add6cd;
  --white-color: #ffffff;
  --black-color: #000000;
  --black-text-color: #222525;
  --black-light-color: #4e5151;
}

.common_page {
  background-color: var(--primary-color);
  padding-top: 72px;
}
.fw-700 {
  font-weight: 700;
}
.common_btn {
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  padding: 10px 31px !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 21px !important;
  font-family: 'DM Sans', sans-serif !important;
}

.common_modal_main_div.modal {
  transform: unset !important;
}

.card_tags {
  border-radius: 20px;
  color: #000000;
  font-weight: 700;
  font-family: 'DM Sans', sans-serif;
  font-size: 12px;
  line-height: 16px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 16px;
  width: max-content;
}
.light_orange {
  background-color: var(--light-orange);
}
.light_green {
  background-color: var(--light-green);
}
.section_div {
  background: #fff;
  /* box-shadow: 0px 8px 24px 0px #4545501a; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 20px 0px;
  position: relative;
  /* z-index: 0; */
}
.custom_container {
  max-width: 1286px !important;
}
.scroll_contain_div {
  height: calc(100vh - 175px);
  overflow-x: auto;
}
.scroll_contain_div_is_free {
  height: calc(100vh - 75px);
  overflow-x: auto;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .z_index_div .sticky-footer {
    display: none;
  }
  .z_index_div .mobile-button-stick {
    display: none !important;
  }
  .mob_w_100 {
    width: 100%;
  }
  .section_div {
    padding: 20px 8px;
  }
  .common_btn {
    padding: 12px 31px !important;
  }
  .scroll_contain_div {
    height: inherit;
    overflow-x: inherit;
    padding-bottom: 130px !important;
  }
  .scroll_contain_div_is_free {
    height: inherit;
    overflow-x: inherit;
    padding-bottom: 130px !important;
  }
  .custom_container {
    z-index: 0 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom_container {
    z-index: 0 !important;
  }
}

@media (min-width: 576px) {
  .custom_container {
    max-width: 540px !important;
  }
}

@media (min-width: 768px) {
  .custom_container {
    max-width: 720px !important;
  }
}

@media (min-width: 992px) {
  .custom_container {
    max-width: 960px !important;
  }
}

@media (min-width: 1200px) {
  .custom_container {
    max-width: 1140px !important;
  }
}

@media (min-width: 1400px) {
  .custom_container {
    max-width: 1286px !important;
  }
}

/* New Component Global Css End */

/* Trusted by learners section css start */
.fw-500 {
  font-weight: 500;
}
.free-trial-btn {
  margin-top: 48px;
}

.trusted_learners_grid_div {
  display: grid;
  grid-template-columns: 45% 55%;
  /* align-items: center; */
  height: 100vh;
}
.logo_div {
  margin-bottom: 160px;
}
.logo_div .logo_img {
  max-width: 77px;
}
.trusted_learners_grid_div .left_sec_div {
  padding-left: 32px;
  padding-top: 20px;
  padding-bottom: 35px;
  padding-right: 32px;
  border-right: 1px solid #ecebea;
  box-shadow: 3px 3px 6px 0px #0000000d;
  background-color: #ffffff;
  height: 100%;
}
.trusted_learners_grid_div .right_sec_div {
  background-color: #068466;
  padding: 20px 0px !important;
  height: 100%;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  z-index: -1;
  overflow-x: hidden; */
  display: flex;
  align-items: center;
  width: 100%;
}
.trusted_learners_grid_div .right_sec_div .trusted_sec {
  width: 100%;
}
.section_Heading {
  font-family: 'Zilla Slab', serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}

.reviews_sec {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 481px;
  margin: 0 auto;
  column-gap: 30px;
  row-gap: 20px;
  text-align: center;
}

.review_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 37px;
}

.available_app_sec {
  margin-top: 30px;
  margin-bottom: 36px;
  padding-left: 30px;
  padding-right: 30px;
}
.available_text {
  font-family: 'Zilla Slab', serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}

.app_icon {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 34px;
}
.app_icon_box {
  background: #00000026;
  padding: 12px;
  height: 56px;
  width: 56px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app_icon_img {
  max-width: 32px;
}

.testimonial_margin_sec {
  /* padding-left: 48px; */
}
.common_testimonial_card {
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0px 8px 24px 0px #4545501a;
  max-width: 560px;
  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 16px;
}
.green_card {
  background: linear-gradient(155deg, rgba(238, 248, 245, 1) 0%, rgba(202, 234, 223, 1) 100%);
}
.green_card svg path {
  fill: #49a48e !important;
}
.orange_card {
  background: linear-gradient(155deg, rgba(238, 247, 236, 1) 0%, rgba(247, 224, 188, 1) 100%);
}
.orange_card svg path {
  fill: #f1a15f !important;
}

.testimonial_text {
  font-family: 'DM Sans', sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 19.53px;
  text-align: left;
  color: #222525;
  margin-bottom: 16px;
}

.host_name {
  font-family: 'DM Sans', sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 19.53px;
  text-align: left;
  color: #4e5151;
  margin-bottom: 0px;
}
.star_div {
  margin-bottom: 16px;
  display: flex;
  gap: 2px;
}
.testimonial_margin_sec .rfm-initial-child-container {
  align-items: flex-start !important;
}
.testimonial_margin_sec .rfm-marquee {
  align-items: flex-start !important;
}
.supercharge_main_div .login-field-main-div {
  margin: 53px auto 0 auto;
}
.supercharge_main_div .common-title-sec {
  max-width: 416px;
  margin: 0 auto;
}
.light_gray_color {
  color: #4e5151 !important;
  font-weight: 500;
}
.mob_logo_div {
  display: none;
}
.mob_logo_div img {
  max-width: 77px;
}
.stapper-logo-main-div {
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 5px;
}
.stapper-logo-main-div img {
  max-width: 77px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .trusted_learners_grid_div {
    grid-template-columns: 100%;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
  }
  .trusted_learners_grid_div .left_sec_div {
    padding-left: 24px;
    padding-top: 50px;
    padding-bottom: 70px;
    padding-right: 24px;
    border-right: 0px solid #ecebea;
    box-shadow: unset;
  }
  .logo_div {
    margin-bottom: 60px;
  }
  .reviews_sec {
    max-width: 360px;
    column-gap: 15px;
    row-gap: 15px;
  }
  .app_icon {
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
  .app_icon {
    gap: 12px;
  }
  .available_app_sec {
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 41px;
  }
  .common_testimonial_card {
    max-width: 280px;
  }
  .section_Heading {
    padding-left: 15px;
    padding-right: 15px;
  }
  .reviews_sec {
    padding-left: 24px;
    padding-right: 24px;
    max-width: 440px;
    column-gap: 21px;
    row-gap: 24px;
  }
  .mob_logo_div {
    display: block;
    padding-left: 33px;
    margin-bottom: 40px;
  }
  .trusted_learners_grid_div .right_sec_div {
    padding: 36px 0px 44px 0px !important;
  }
  .trusted_sec .section_Heading {
    margin-bottom: 31px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .mob_hide_img {
    display: none;
  }
  .bottom-section .second {
    margin-top: 46px;
  }
  .trusted_learners_grid_div .login-field-main-div {
    margin: 43px auto 0 auto;
  }
  .trusted_learners_grid_div .login-field-main-div .free-trial-btn {
    margin-top: 42px;
  }
  .stapper-logo-main-div {
    margin-top: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .trusted_learners_grid_div .left_sec_div {
    padding-left: 20px;
    padding-right: 20px;
  }
  .reviews_sec {
    max-width: 365px;
    column-gap: 25px;
    row-gap: 25px;
    text-align: center;
  }
  .review_card_box img {
    max-width: 165px;
  }
  .common_testimonial_card {
    padding: 16px;
  }
  .testimonial_text {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 12px;
  }
  .host_name {
    font-size: 13px;
  }
  .star_div svg {
    width: 15px;
    height: 15px;
  }
  .star_div {
    margin-bottom: 12px;
  }
  .app_icon {
    gap: 10px;
    margin-bottom: 25px;
  }
  .logo_div {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .trusted_learners_grid_div .left_sec_div {
    padding-left: 25px;
    padding-right: 25px;
  }
  .reviews_sec {
    max-width: 400px;
    column-gap: 25px;
    row-gap: 25px;
    text-align: center;
  }
  .review_card_box img {
    max-width: 185px;
  }
  .common_testimonial_card {
    padding: 16px;
  }
  .testimonial_text {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 12px;
  }
  .host_name {
    font-size: 14px;
  }
  .star_div svg {
    width: 18px;
    height: 18px;
  }
  .star_div {
    margin-bottom: 12px;
  }
  .app_icon {
    margin-bottom: 25px;
  }
  .logo_div {
    margin-bottom: 60px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .section_Heading {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .review_card_box img {
    max-width: 165px;
  }
  .reviews_sec {
    row-gap: 15px;
    column-gap: 20px;
  }
  .available_app_sec {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .app_icon {
    margin-bottom: 20px;
  }
  .common_testimonial_card {
    margin-bottom: 10px;
    padding: 15px 20px;
  }
  .logo_div {
    margin-bottom: 100px;
  }
  .star_div {
    margin-bottom: 8px;
  }
  .testimonial_text {
    margin-bottom: 10px;
  }
}

/* Trusted by learners section css end */
