.user_info_main_div {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #00000033;
  justify-content: space-between;
}
.days_text {
  color: var(--black-text-color);
  margin-left: 7px;
  margin-bottom: 0px;
  font-size: 15px;
  font-weight: 700;
  font-family: 'DM Sans', sans-serif;
  line-height: 20px;
}
.user_info_main_div h6 {
  color: var(--black-text-color);
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'DM Sans', sans-serif;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .user_info_main_div {
    flex-direction: column;
    padding-bottom: 10px;
    align-items: flex-start;
  }
  .user_info_main_div h6 span {
    display: block;
    font-size: 16px;
    line-height: 21px;
  }
  .user_info_main_div h6 {
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 20px;
  }
}
