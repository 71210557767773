.card_sec_main_div {
  margin-top: 45px;
}
.main_title_text {
  color: var(--black-text-color);
  font-family: 'Zilla Slab', sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: 34px;
}
.main_title_text span {
  color: var(--black-light-color);
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  font-family: 'DM Sans', sans-serif;
}
.card_box_div {
  background-color: var(--white-color);
  box-shadow: 0px 8px 24px 0px #4545501a;
  border-radius: 6px;
  padding-left: 30px;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  column-gap: 67px;
  position: relative;
  margin-bottom: 24px;
  cursor: pointer;
}
.card_box_div .left_sec {
  min-width: 198px;
}
.left_sec_img {
  width: 166px;
  max-width: 166px !important;
  /* height: 154px; */
  border-radius: 6px;
}
.left_sec_img.gary {
  filter: grayscale(1);
}
.card_name_text {
  color: var(--black-text-color);
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  font-family: 'Noto Sans SC', sans-serif;
  margin-bottom: 16px;
}
.card_para_text {
  color: var(--black-light-color);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  font-family: 'DM Sans', sans-serif;
  margin-bottom: 25px;
}
.contains_divider {
  background: #00000033;
  height: 16px;
  width: 1px;
}
.contains_footer_div {
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
}
.contains_footer_div label {
  margin-bottom: 0px;
  margin-left: 8px;
  color: var(--black-text-color);
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  font-family: 'DM Sans', sans-serif;
}
.check_icon_div {
  position: absolute;
  top: 20px;
  right: 30px;
}
.story_creator_tooltip:hover .tooltip_creator_contains {
  display: block;
}
.story_creator_tooltip img {
  max-width: 51px;
}
.words_known_tooltip:hover .tooltip_words_known_contains {
  display: block;
}
.new_words_tooltip:hover .tooltip_new_words_contains {
  display: block;
}
.tooltip_creator_contains {
  background: var(--white-color);
  border: 1px solid #ededed;
  box-shadow: 0px 4px 6px 0px #2225250f;
  padding: 16px;
  border-radius: 8px;
  position: absolute;
  bottom: -60px;
  left: 0px;
  min-width: 420px;
  display: none;
  z-index: 999;
}
.tooltip_words_known_contains {
  background: var(--white-color);
  border: 1px solid #ededed;
  box-shadow: 0px 4px 6px 0px #2225250f;
  padding: 16px;
  border-radius: 8px;
  position: absolute;
  bottom: -60px;
  left: 60px;
  min-width: 420px;
  display: none;
  z-index: 999;
}
.tooltip_new_words_contains {
  background: var(--white-color);
  border: 1px solid #ededed;
  box-shadow: 0px 4px 6px 0px #2225250f;
  padding: 16px;
  border-radius: 8px;
  position: absolute;
  bottom: -80px;
  left: 235px;
  min-width: 420px;
  display: none;
  z-index: 999;
}
.tooltip_text {
  color: #222525b2;
  font-size: 15px;
  font-weight: 400;
  font-family: 'DM Sans', sans-serif;
  line-height: 20px;
  margin-bottom: 0px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .card_sec_main_div {
    margin-top: 23px;
  }
  .main_title_text {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 26px;
  }
  .main_title_text span {
    display: block;
    margin-top: 10px;
  }
  .card_box_div {
    flex-wrap: wrap;
    row-gap: 25px;
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .card_box_div .left_sec {
    display: flex;
    justify-content: center;
    width: 100%;
    min-width: auto;
  }

  .left_sec_img {
    width: 140px;
    max-width: 140px !important;
    border-radius: 6px;
  }
  .check_icon_div {
    top: 15px;
    right: 15px;
  }
  .check_icon_div img {
    max-width: 22px;
  }
  .card_name_text {
    margin-bottom: 14px;
    word-wrap: break-word;
  }
  .contains_footer_div {
    gap: 8px 12px;
    flex-wrap: wrap;
    max-width: 300px;
  }
  .card_box_div .left_sec img {
    max-width: 140px;
  }
  .card_para_text {
    word-wrap: break-word;
    margin-bottom: 22px;
  }
  .contains_footer_hover {
    min-width: auto;
  }
  .tooltip_creator_contains {
    bottom: -55px;
    min-width: 250px;
  }
  .tooltip_words_known_contains {
    bottom: -55px;
    min-width: 250px;
    left: 10px;
  }
  .tooltip_new_words_contains {
    bottom: -80px;
    left: 0px;
    min-width: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tooltip_new_words_contains {
    bottom: -80px;
    left: 150px;
    min-width: 250px;
  }
  .tooltip_words_known_contains {
    bottom: -80px;
    left: 70px;
    min-width: 250px;
  }
  .tooltip_creator_contains {
    bottom: -80px;
    left: 0px;
    min-width: 250px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tooltip_new_words_contains {
    min-width: 350px;
    bottom: -80px;
  }
  .tooltip_words_known_contains {
    min-width: 350px;
    bottom: -80px;
  }
  .tooltip_creator_contains {
    min-width: 350px;
    bottom: -80px;
  }
}
