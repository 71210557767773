.header_main_div {
  background-color: var(--primary-color);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999;
  width: 100%;
}
.header_main_div .navbar {
  padding-top: 18px;
  padding-bottom: 18px;
}
.navbar-toggler {
  padding: 0px;
  border: 0px;
}
.header_plan_text {
  font-size: 20px;
  font-weight: 700;
  font-family: 'DM Sans', sans-serif;
  line-height: 25px;
  color: var(--white-color);
  margin-left: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 0px;
}
.header_plan_text img {
  max-width: 72px;
}
.header_plan_text span {
  position: relative;
  top: -1px;
}
.header_menu_div {
  column-gap: 36px;
}
.header_menu_div .nav-link {
  color: var(--white-color);
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  font-family: 'DM Sans', sans-serif;
  display: flex;
  align-items: center;
  column-gap: 10px;
  transition: none !important;
  position: relative;
  cursor: pointer;
}
.header_menu_div .nav-link:hover > svg path {
  fill: #6fc9b4 !important;
}
.header_menu_div .nav-link:hover {
  color: #6fc9b4 !important;
}
.header_menu_div .nav-link:focus,
.header_menu_div .nav-link.active {
  color: #ffffff !important;
}
.header_menu_div .dropdown-toggle::after {
  display: none !important;
}
.header_menu_div .dropdown-menu {
  background: #ecebea !important;
  border: 1px solid #ededed !important;
  box-shadow: 0px 4px 6px 0px #2225250f !important;
  left: -50px !important;
}
.header_menu_div .dropdown-menu .dropdown-item {
  color: var(--black-text-color);
  font-size: 15px;
  font-weight: 400;
  line-height: 32px;
  display: flex;
  align-items: center;
  column-gap: 11px;
}
.header_menu_div .dropdown-menu .dropdown-item svg {
  visibility: hidden;
}
.header_menu_div .dropdown-menu .dropdown-item:hover {
  background: #d6d6d6 !important;
  color: var(--primary-color) !important;
}
.header_menu_div .dropdown-menu .dropdown-item:hover svg {
  visibility: visible;
}
.header_menu_div .dropdown-menu .dropdown-item:focus,
.header_menu_div .dropdown-menu .dropdown-item:active {
  background: #d6d6d6 !important;
  color: var(--primary-color) !important;
}
.header_menu_div .dropdown-menu .dropdown-item:focus svg,
.header_menu_div .dropdown-menu .dropdown-item:active svg {
  visibility: visible !important;
}
.header_count_div {
  background-color: #e95a1d;
  height: 16px;
  min-width: 16px;
  max-width: 16px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  margin-bottom: 0px;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  justify-content: center;
  width: 100%;
}
.nav_toggle_div .navbar-toggler {
  padding: 0px;
  border: 0px;
  outline: none !important;
  box-shadow: unset !important;
}
.nav_toggle_div .navbar-toggler-icon {
  filter: brightness(0) invert(1);
}
.custom_dropdown_main_div {
  position: relative;
}
.custom_dropdown_div {
  position: absolute;
  background-color: #ecebea;
  min-width: 134px;
  z-index: 999;
  margin-top: 0;
  text-align: left;
  padding: 15px 0px 10px 0px;
  border-radius: 5px;
  border: 1px solid #ededed;
  box-shadow: 0px 4px 6px 0px #2225250f;
  left: -88px;
  top: 43px;
}
.custom_dropdown_div .up_arrow_div {
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid #ecebea;
  position: absolute;
  right: 20px;
  top: -11px;
}
.header_menu_div .dropdown-menu .up_arrow_div {
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid #ecebea;
  position: absolute;
  right: 20px;
  top: -11px;
}
.custom_dropdown_div ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.custom_dropdown_div .dropdown_link {
  font-size: 15px;
  color: #222222;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 30px;
  padding-right: 10px;
  font-weight: 400;
  cursor: pointer;
  position: relative;
}
.custom_dropdown_div ul .dropdown_link.active {
  background: rgba(0, 0, 0, 0.09);
  color: #068466;
  position: relative;
}
.custom_dropdown_div ul .dropdown_link.active::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #068466;
  border-radius: 60px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 18px;
}
.custom_dropdown_div .dropdown_link:hover {
  background: #d6d6d6 !important;
  color: var(--primary-color) !important;
}
.custom_dropdown_div .dropdown_link svg {
  visibility: hidden;
}
.custom_dropdown_div .dropdown_link:hover svg {
  visibility: visible;
}
.custom_dropdown_main_div {
  display: none;
}
.mob_link {
  display: none;
}
.mob_header_div {
  display: none;
}

.nav_custom_dropdown_menu {
  position: absolute;
  background-color: #ecebea;
  min-width: 134px;
  z-index: 999;
  margin-top: 0;
  top: 45px;
  left: -15px;
  text-align: left;
  padding: 15px 0px 10px 0px;
  border-radius: 5px;
  border: 1px solid #ededed;
  box-shadow: 0px 4px 6px 0px #2225250f;
}
.nav_custom_dropdown_menu::before {
  content: '';
  position: absolute;
  top: -13px;
  right: 20px;
  z-index: 99;
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid #ecebea;
}
.nav_custom_dropdown_menu ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.nav_custom_dropdown_menu ul li:hover {
  background: rgba(0, 0, 0, 0.09);
}
.nav_custom_dropdown_menu ul li.active {
  background: rgba(0, 0, 0, 0.09);
  color: #068466;
  position: relative;
}
.nav_custom_dropdown_menu ul li.active::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #068466;
  border-radius: 60px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 18px;
}
.nav_custom_dropdown_menu ul li {
  font-size: 16px;
  color: #222222;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 30px;
  padding-right: 10px;
  font-weight: 500;
  cursor: pointer;
}

.custom_dropdown_div_one {
  position: absolute;
  background-color: #ecebea;
  min-width: 134px;
  z-index: 999;
  margin-top: 0;
  text-align: left;
  padding: 15px 0px 10px 0px;
  border-radius: 5px;
  border: 1px solid #ededed;
  box-shadow: 0px 4px 6px 0px #2225250f;
  right: 258px;
  top: 43px;
}
.custom_dropdown_div_one .up_arrow_div {
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid #ecebea;
  position: absolute;
  right: 20px;
  top: -11px;
}
.header_menu_div .dropdown-menu .up_arrow_div {
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 13px solid #ecebea;
  position: absolute;
  right: 20px;
  top: -11px;
}
.custom_dropdown_div_one ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.custom_dropdown_div_one .dropdown_link {
  font-size: 15px;
  color: #222222;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 30px;
  padding-right: 10px;
  font-weight: 400;
  cursor: pointer;
  position: relative;
}
.custom_dropdown_div_one ul .dropdown_link.active {
  background: rgba(0, 0, 0, 0.09);
  color: #068466;
  position: relative;
}
.custom_dropdown_div_one ul .dropdown_link.active::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #068466;
  border-radius: 60px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 18px;
}
.custom_dropdown_div_one .dropdown_link:hover {
  background: #d6d6d6 !important;
  color: var(--primary-color) !important;
}
.custom_dropdown_div_one .dropdown_link svg {
  visibility: hidden;
}
.custom_dropdown_div_one .dropdown_link:hover svg {
  visibility: visible;
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .header_main_div {
    position: static;
  }
  .common_page {
    padding-top: 0px;
  }
  /* .header_main_div .navbar-collapse {
    background-color: var(--primary-color);
    position: fixed;
    left: 0px;
    width: 100%;
    top: 0px;
    height: 100%;
    transition: none !important;
  } */

  .header_main_div .navbar-collapse1 {
    background-color: var(--primary-color);
    /* position: absolute; */
    width: 100%;
    /* z-index: 99; */
    min-height: 100vh;
    /* transition: none !important; */

    /* transition: all 1s ease-out !important; */
    transition: all 1s ease-in !important;
  }
  /* .header_main_div .navbar-collapse1 .header_menu_div {
      background-color: #000000;
      height: calc(100vh - 73px);
      position: relative;
      z-index: 99;
      transition: all 1s ease-in !important;
  } */

  .test1 {
    max-width: unset;
    max-height: fit-content;
    transition: all 1s ease-in;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }

  .show-menu {
    visibility: visible;
    transition: height 0.3s ease-in;
    height: 100vh;
    background: #d8d8d8;
  }

  .show-menu.hide {
    visibility: hidden;
    height: 0;
    width: 0;
  }

  .nav_toggle_div .close_icon {
    margin-top: -3px;
  }

  .custom_dropdown_main_div {
    display: block !important;
    position: absolute;
    top: 7px;
    right: 60px;
  }
  .mob_hide {
    display: none !important;
  }
  .header_menu_div .nav-link svg {
    display: none;
  }
  .header_menu_div .nav-link i {
    display: none;
  }
  .header_menu_div .nav-link {
    width: 100%;
    justify-content: center;
    padding-top: 19px;
    padding-bottom: 19px;
    font-size: 18px;
    line-height: 24px;
  }
  .logout_link {
    display: block !important;
    text-align: center;
    padding-top: 23px !important;
    margin-top: 19px;
  }
  .divider_cls {
    border-top: 1px solid #ffffff33 !important;
  }
  .mob_header_div {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 24px;
    margin-bottom: 64px;
  }
  .header_count_div {
    display: none;
  }
  .mob_dropdown_link {
    display: none !important;
  }
  .disable-correction-container.new-tooltip {
    position: relative;
  }
  .disable-correction-tooltip.new-tooltip-conation {
    position: absolute;
    top: 62px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 999;
  }
  .new-tooltip-conation .tooltiptextWriter {
    position: relative !important;
    left: 0px !important;
  }
}
/* @media screen and (min-width: 993px) {
  .custom_dropdown_main_div:hover .custom_dropdown_div {
    display: block;
  }
} */
