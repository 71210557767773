.upgrade_box_main_div {
  display: flex;
  box-shadow: 0px 8px 24px 0px #4545501a;
  background-color: var(--white-color);
  padding: 13px 35px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background-image: linear-gradient(
    112deg,
    rgba(255, 244, 235, 1) 0%,
    rgba(254, 229, 206, 1) 40%,
    rgba(252, 236, 205, 1) 62%,
    rgba(254, 247, 234, 1) 100%
  );
}
.upgrade_box_main_div .left_div {
  display: flex;
  align-items: center;
  column-gap: 45px;
}
.upgrade_contains h5 {
  color: var(--black-color);
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 6px;
  font-family: 'Zilla Slab', sans-serif;
}
.upgrade_contains p {
  font-family: 'DM Sans', sans-serif;
  color: var(--black-light-color);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0px;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .upgrade_box_main_div {
    flex-wrap: wrap;
    padding: 15px 20px;
  }
  .upgrade_box_main_div .left_div {
    flex-wrap: wrap;
    width: 100%;
  }
  .upgrade_box_main_div .left_div .img_box {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .upgrade_box_main_div .right_div {
    width: 100%;
  }
  .upgrade_contains h5 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 18px;
    font-family: 'DM Sans', sans-serif;
  }
  .upgrade_contains p {
    margin-bottom: 19px;
  }
}
