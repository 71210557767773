.skeleton_loader_main_div {
  margin-top: 24px;
}
.skeleton_loader_card_div {
  column-gap: 99px;
  background-color: var(--white-color);
  box-shadow: 0px 8px 24px 0px #4545501a;
  border-radius: 6px;
  padding-left: 30px;
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 24px;
  min-height: 215px;
}
.skeleton_loader_cls {
  background: linear-gradient(to right, rgb(124 124 124 / 0%), rgb(203 203 203 / 54%) 50%, rgb(177 177 177 / 0%) 80%),
    #eeeeee;
  background-repeat: repeat-y;
  background-size: 60px 10px;
  animation: shine 1.5s infinite;
}
.skeleton_loader_card_div .left_sec_box {
  width: 166px;
  min-width: 166px;
  height: 154px;
  border-radius: 6px;
}
.skeleton_loader_card_div .right_sec_box {
  width: 100%;
  min-height: 160px;
}

.card_name_box1 {
  height: 32px;
  border-radius: 6px;
  width: 100%;
  max-width: 302px;
  margin-bottom: 13px;
}
.card_name_box2 {
  height: 21px;
  border-radius: 6px;
  width: 100%;
  max-width: 302px;
  margin-bottom: 26px;
}
.card_name_box3 {
  height: 21px;
  border-radius: 6px;
  width: 100%;
  max-width: 545px;
}
.user_info_skeleton_loader {
  background-color: var(--white-color);
  border-bottom: 1px solid #00000033;
  padding-bottom: 20px;
  border-radius: 0px;
  min-height: 49px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.user_info_skeleton_left,
.user_info_skeleton_right {
  background: linear-gradient(to right, rgb(124 124 124 / 0%), rgb(203 203 203 / 54%) 50%, rgb(177 177 177 / 0%) 80%),
    #eeeeee;
  background-repeat: repeat-y;
  background-size: 60px 10px;
  animation: shine 1.5s infinite;
  height: 20px;
  border-radius: 6px;
  width: 100%;
}
.user_info_skeleton_left {
  width: 100%;
  max-width: 300px;
}
.user_info_skeleton_right {
  width: 100%;
  max-width: 160px;
}

@keyframes shine {
  to {
    background-position:
      100% 0,
      /* move highlight to right */ 0 0;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .skeleton_loader_card_div {
    flex-wrap: wrap;
    grid-row-gap: 25px;
    row-gap: 25px;
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .left_sec_box.skeleton_loader_cls {
    width: 140px;
    height: 120px;
    min-width: 140px !important;
    margin: 0 auto;
  }
  .user_info_skeleton_loader {
    flex-wrap: wrap;
    padding-bottom: 6px;
    padding-top: 5px;
  }
  .user_info_skeleton_left {
    min-height: 42px;
  }
}
